





























import { Notify } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import List from "@/components/ShareComponent/PullDownUp.vue"
import { HandStore } from "@/store/modules/Hand/Hand"
import Stroage from "@/util/Storage"
import { HandTypeT } from "@/Type/index"
import { HandType } from "@/Api/Hand/index"
import { Vue,Component } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface HandTypeListType<T=string>{
    loadingShow:boolean;
    userId:T|number;
    token:T;
    TypeList:Array<any>
    TypeIndex:number

    initData():void;
    handleChangeType(data:any,index:number):void;
    handleUpTypeList():void;
    handleError(data:string):void;
}

@Component({ name:"TypeList",components:{ List,HeadTop } })
export default class HandTypeList extends ZoomPage implements HandTypeListType{
   loadingShow = false
   userId: string|number = ""
   token = ""
   TypeList:Array<HandTypeT> = [ ]
   TypeIndex = 0

   mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
       this.initData()
   }

   initData(){
       if( HandStore.GetHandTypeList.length ){
           this.TypeList = HandStore.GetHandTypeList
           if( "id" in HandStore.GetHandIssueActiveT ){
               let Index = this.TypeList.findIndex( a=>a.id === HandStore.GetHandIssueActiveT.id )
               if( Index < 0 )Index = 0
               this.TypeIndex = Index
           }
       }else{
           this.handleUpTypeList()
       }
   }

   handleChangeType(data:any,index:number){
       if( this.TypeIndex != index ){
           this.TypeIndex = index
           HandStore.SetHandIssueActiveT( data )
           this.$router.go(-1)
       }
   }

   handleUpTypeList(){
       this.loadingShow = true
       HandType({
           userId:this.userId,
           token:this.token
       }).then(res=>{
           this.loadingShow = false
           if( res.message.code === "200" ){
               this.TypeList = res.data
               HandStore.SetHandTypeList( res.data )
           }else{
               this.handleError( res.message.msg )
           }
       })
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }
}
